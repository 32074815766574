<template>
  <div id="home">
    <div class="home-header">
      <div class="header-title">
        <div class="header-title-left" @click="goToHome">
          <img class="left-logo point" src="../img/logo-blue.png" alt="">
        </div>
        <div class="header-title-right">
          <div class="nav">
            <div class="li" v-for="(item,index) in menuList" :key="index" @mouseover="changeContent(item)">
              <a class="point"  @click.stop="menuTap(item)">{{item.name}}</a>
              <div :class="['ysolu_nav', 'f-cb', activeHeight]" v-show="item.children">
                <div class="oneli" v-for="(ite,ind) in item.children" :key="ind">
                  <a class="tit"  @click.stop="menuTap(ite)">{{ite.subheading}}</a>
                  <!-- <div class="sub-tits f-cb">
                    <div class="ysoright f-cb">
                      <div class="ptcp">
                        <div class="tit-28  hnb mc lh1">12345</div>
                        <div class="plist">
                          <a class="tit-18 lh2 hnr">
                            <p>5555</p>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div> -->
                </div>
              </div>
            </div>  
          </div>
        </div>
      </div>
    </div>
    <div class="headaerfix"></div>
    <div>
      <router-view></router-view>
    </div>
    <div class="scroll-top" :class="isScroll?'scroll-top-show':''" @click="scrollTop">
      <i class="scroll-icon el-icon-arrow-up"></i>
    </div>
    <div class="home-footer">
      <div class="footer">
        <div class="footer-top">
          <div class="left">
            <div class="logo">
              <img src="../img/logo-min.png" alt="">
            </div>
            <div class="tit-16 gp">迪倍特</div>
            <div class="list">
              <a class="ewm-box" href="javascript:;">
                <svg t="1632413354766" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="1996" width="24" height="24">
                  <path d="M664.250054 368.541681c10.015098 0 19.892049 0.732687 29.67281 1.795902-26.647917-122.810047-159.358451-214.077703-310.826188-214.077703-169.353083 0-308.085774 114.232694-308.085774 259.274068 0 83.708494 46.165436 152.460344 123.281791 205.78483l-30.80868 91.730191 107.688651-53.455469c38.558178 7.53665 69.459978 15.308661 107.924012 15.308661 9.66308 0 19.230993-0.470721 28.752858-1.225921-6.025227-20.36584-9.521864-41.723264-9.521864-63.862493C402.328693 476.632491 517.908058 368.541681 664.250054 368.541681zM498.62897 285.87389c23.200398 0 38.557154 15.120372 38.557154 38.061874 0 22.846334-15.356756 38.156018-38.557154 38.156018-23.107277 0-46.260603-15.309684-46.260603-38.156018C452.368366 300.994262 475.522716 285.87389 498.62897 285.87389zM283.016307 362.090758c-23.107277 0-46.402843-15.309684-46.402843-38.156018 0-22.941502 23.295566-38.061874 46.402843-38.061874 23.081695 0 38.46301 15.120372 38.46301 38.061874C321.479317 346.782098 306.098002 362.090758 283.016307 362.090758zM945.448458 606.151333c0-121.888048-123.258255-221.236753-261.683954-221.236753-146.57838 0-262.015505 99.348706-262.015505 221.236753 0 122.06508 115.437126 221.200938 262.015505 221.200938 30.66644 0 61.617359-7.609305 92.423993-15.262612l84.513836 45.786813-23.178909-76.17082C899.379213 735.776599 945.448458 674.90216 945.448458 606.151333zM598.803483 567.994292c-15.332197 0-30.807656-15.096836-30.807656-30.501688 0-15.190981 15.47546-30.477129 30.807656-30.477129 23.295566 0 38.558178 15.286148 38.558178 30.477129C637.361661 552.897456 622.099049 567.994292 598.803483 567.994292zM768.25071 567.994292c-15.213493 0-30.594809-15.096836-30.594809-30.501688 0-15.190981 15.381315-30.477129 30.594809-30.477129 23.107277 0 38.558178 15.286148 38.558178 30.477129C806.808888 552.897456 791.357987 567.994292 768.25071 567.994292z" p-id="1997" fill="#ffffff"></path>
                </svg>
                <div class="ewm"><img src="../img/erweima.png" alt=""></div>
              </a>
            </div>
          </div>
          <div class="right">
                <div class="nav f-cb">
                  <div class="li" v-for="(item,index) in navList" :key="index" @click="menuTap(item)">
                    <a class="tit-24 col-fff hnr point">{{item.name}}</a>
                  </div>
                </div>
                <div class="tit-16 text-right cf5 hnr">
                    <p>迪倍特信息科技有限公司</p>
                    <p>地址：西安市高新区高新三路8号橙市区空间5楼0520室</p>
                    <p>电话: 400-029-2281（总机转） </p>
                </div>
            </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from '../components/Swiper';
export default {
  components: {
    Swiper
  },
  data() {
    return {
      name: '',
      roleId: 0,
      currentMenu: 'index',
      menuList: [
        { router: 'index', name: '首页' },
        { name: '系统集成',
          router: 'waterSystem',  
          children: [
            {subheading: '城乡一体化数字孪生水务系统', router: 'waterSystem'},
            {subheading: '无人值守泵站控制系统', router: 'controlSystem'},
            {subheading: '人员巡点检智能运维系统', router: 'operatingSystem'},
            {subheading: '物联网IoT设备管理与数据采集系统', router: 'acquisitionSystem'},
            {subheading: '全域数据分析与报表系统', router: 'reportingSystem'},
            {subheading: '水务营收及抄表管理系统', router: 'operatingSystem'},
            {subheading: '水库在线监测管理系统', router: 'reservoirMonitoringSystem'},
            {subheading: '智慧河湖河道监测管理系统', router: 'managementSystem'},
            {subheading: '节水灌溉系统', router: 'irrigationSystems'},
          ]
        },
        { name: '核心技术', 
          router: 'iotMiddleware',
          children: [
            {subheading: '物联网中台', router: 'iotMiddleware'},
            {subheading: '无线数据采集系统', router: 'wirelessDataAcquisition'},
            {subheading: 'WEB SCADA 系统', router: 'webSCADA'},
            {subheading: 'GIS与数字孪生系统', router: 'gisDigitalTwin'},
          ],
        },
        { router: 'about', name: '公司简介' },
        { router: 'contactUs', name: '联系我们' },
      ],
      isScroll: false,
      navList: [
        { router: 'about', name: '公司简介' },
        { router: 'contactUs', name: '联系我们' },
      ],
      currentData: null,
      isActive: false,
    }
  },
  computed: {
    activeHeight() {
      return this.isActive ? 'active-height' : '';
    }
  },
  watch:{
    '$route.path':function(newVal,oldVal){
      this.currentMenu = newVal.substring(1)
    }
  },
  methods: {
    //返回首页
    goToHome() {
      this.$router.push('/index');
    },
    // 菜单item项
    menuTap(item) {
      this.currentMenu = item.router
      sessionStorage.setItem('currentMenu', item.router)
      this.$router.push({ name: item.router })
    },
    changeContent(item) {
      if (item.name === '系统集成') {
        this.isActive = true;
      } else {
        this.isActive = false;
      }
    },
    // 监听滚动条
    handleScroll() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > 1700) {
        this.isScroll = true
      }else {
        this.isScroll = false
      }
    },
    // 回到顶部
    scrollTop() {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if(scrollTop > 0) {
        const timeTop = setInterval(() => {
          document.documentElement.scrollTop = document.body.scrollTop = scrollTop -= 50;
          if(scrollTop <= 0) {
            clearInterval(timeTop)
          }
        },10)
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
    if(sessionStorage.getItem('currentMenu')) {
      this.currentMenu = sessionStorage.getItem('currentMenu')
    }
  }
}
</script>

<style lang="less" scoped>
#home {
  margin: 0;
  line-height: 2;
  color: #111;
  font-family: "Microsoft YaHei";
}
// web端样式
.home-header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  border-bottom: 1px solid rgba(255, 255, 255, .1);
  z-index: 999999999;
}
.header-title {
  height: 6.25rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #fff;
  padding: 0 12rem;
}
.headaerfix {
  position: relative;
  width: 100%;
  height: 6.25rem;
}
.header-title-left {
  .left-logo {
    height: 3rem;
    margin-right: 5rem;
  }
}
.header-title-right {
  // display: flex;
  // height: 100%;
  // justify-content: flex-start;
  // align-items: center;
  .nav {
    display: flex;
    height: 100%;
    justify-content: flex-start;
    align-items: center;
    margin-top: 0 !important;
    .li {
      height: 100%;
      line-height: 100px;
      font-size: 18px;
      padding: 0 25px;
      .active-height {
        height: 116px !important;
        padding-left: 10% !important;
        line-height: 0;
      }
      .ysolu_nav {
        width: 100vw;
        height: 56px;
        background-color: #0041c3;
        position: absolute;
        left: 0;
        top: 100px;
        padding-left: 30%;
        display: none;
        .oneli {
          display: inline-block;
          padding: 0 48.75px;
          line-height: 56px;
          vertical-align: top;
          color: #fff;
          // opacity: 0.6;
          .sub-tits {
            width: 100vw;
            height: 216px;
            background-color: #fff;
            position: absolute;
            left: 0;
            top: 56px;
            border-top: 1px solid #00000029;
            display: block;
            .ysoright {
              width: 68%;
              // float: right;
              margin: auto;
              height: 100%;
              background-color: #f1f1f1;
              padding: 40px 0;
              padding-right: 120px;
              padding-left: 94px;
              a {
                width: 26%;
                float: left;
                margin-right: 5%;
                margin-bottom: 0.8rem;
                border-bottom: 1px solid #333;
                color: #434343;
                text-align-last: left;
              }
              a:hover {
                color: #a7a4a4;
                opacity: 1;
                cursor: pointer;
              }
            }
          }
          a:hover {
            color: #fff;
            cursor: pointer;
          }
        }
      }
    }  
    .li:hover {
      background-color: #0041c3;
      color: #fff;
    }
    .li:hover .ysolu_nav {
      display: block;
    }
    .ysolu_nav:hover {
      display: block;
    }
  }
}
.scroll-top {
  position: fixed;
  bottom: 13%;
  right: 0.2%;
  z-index: 998;
  width: 40px;
  height: 40px;
  border-radius: 5px;
  background: #FFFFFF;
  color: #0041C3;
  text-align: center;
  cursor: pointer;
  display: none; 
  border: 1px solid #ddd;
  .scroll-icon {
    display: inline-block;
    width: 100%;
    height: 100%;
    line-height: 40px;
    font-size: 22px;
    font-weight: bold;
  }
}
.scroll-top-show {
  display: block;
}

// 底部
.home-footer {
  width: 100%;
  // height: 290px;
  position: relative;
  background: #060c1c;
  // box-sizing: border-box;
  padding-top: 100px;
  .footer {
    max-width: 1536px;
    width: 80%;
    margin: 0 auto;
    .footer-top {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      padding-bottom: 68px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.4);
      .left {
        .logo {
          width: 166px;
          img {
            height: 1.25rem;
            margin-top: 0.4rem;
          }
        }
        .gp {
          color: rgba(255, 255, 255, 0.4);
          margin-top: 4px;
        }
        .list {
          margin-top: .8rem;
          a .ewm {
            position: absolute;
            width: 7.5rem;
            display: none;
          }
          .ewm-box:hover .ewm{
            display: block;
          }
        } 
      }
      .right {
        .nav {
          .li {
            float: right;
            margin-left: 5rem;
            a {
              font-size: 1.25rem;
            }
          }
          .li:last-child {
            margin-right: 0;
          }
        }

      }
    }
  }
}

// web端样式
@media screen and (max-width:1543px) {
  .header-title-right {
    .nav {
      .li {
        font-size: 16px;
        padding: 0 18px;
      }
    }
  }
  .header-title-right .nav .li .ysolu_nav .oneli{
    padding: 0 17.75px !important;
    font-size: 16px  !important;
  }
}

// 移动端样式
@media screen and (max-width:650px) {
  #home {
    padding-top: 44px;
  }
  .home-header {
    height: 44px;
    padding: 0 20px !important;
  }
  .left-logo {
    height: 80px !important;
  }
  .title-right-text {
    font-size: 18px !important;
    padding-right: 20px !important;
  }
  .title-right-icon {
    height: 20px !important;
  }
  .menu-wrapper {
    width: 40%;
    .menu-close {
      height: 44px !important;
      padding: 0 20px;
      span {
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        border: 1px solid #000000;
      }
    }
    .menu-list {
      margin: 50px 0;
      .menu-list-item {
        height: 60px !important;
        line-height: 60px !important;
        span {
          font-size: 14px !important;
        }
      }
    }
    .menu-logo {
      margin-top: 30px;
      img {
        width: 60%;
      }
    }
  }
}
</style>